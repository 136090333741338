import _ from 'lodash';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { NextSeo } from 'next-seo';
import { useEffect, useRef, useState } from 'react';
// delay
import Layout from 'src/components/layouts';
import { IMAGE_DEFAULT, WEB_DESC, WEB_KEYWORD, WEB_NAME, WEB_TITLE, WEB_URL } from 'src/constants/layout';
import { isShouldFetch } from 'src/utils/detect-scrolling';
import { convertDatasets, convertObjPath, dropSomeField, escapeChar, isExistAds, timestamp } from 'src/utils/helper';
import { GET } from 'src/utils/services';
import { KEY_ADS_POSITION } from 'src/components/ads/dynamic-ads';
import { decryptionClient, encryptionClient } from 'src/services/member-auth/utils/decryption-payload';
import SectionMember from 'src/services/member-auth/components/section-member';
import { useDispatch } from 'react-redux';
import { initialLists } from 'src/services/member-auth/redux';
import { IS_NO_ROBOT_INDEX } from 'src/constants';
import useUser from 'src/utils/useUser';

// import Script from 'next/script';
const SectionOne = dynamic(import('src/components/sections/sections-home/section-one'));
const SectionVideo = dynamic(import('src/components/sections/section-video'), { ssr: false });
const SectionTwo = dynamic(import('src/components/sections/sections-home/section-two'), { ssr: false });
// const SectionThaileague = dynamic(import('src/components/sections/section-event/section-thaileague'));
const SectionThree = dynamic(import('src/components/sections/sections-home/section-three'), { ssr: false });
const SectionFour = dynamic(import('src/components/sections/sections-home/section-four'), { ssr: false });
const SectionEvents = dynamic(import('src/components/sections/section-event/section-event-cover'), { ssr: false });
const SectionFive = dynamic(import('src/components/sections/sections-home/section-five'), { ssr: false });
const SectionLandingPage = dynamic(import('src/components/sections/section-event/section-landing-page'), { ssr: false });
const DynamicAds = dynamic(import('src/components/ads/dynamic-ads'));

const sectionsNeedShow = ['section-video', 'section-two', 'section-three', 'section-four', 'section-five'];

const PageIndex = ({ articles, covers, dataLandingPage, dataSectionVideo, ads, dataSetWorldCup, dataTagWorldCup }) => {
  const data = useUser();
  const [sessionSevId, setSessionSevId] = useState(null);
  const [sectionMember, setSectionMember] = useState({});
  useEffect(async () => {
    const checkUser = async () => {
      if (!_.isEmpty(data) && !_.isEmpty(data?.user)) {
        const response = await fetch('/api/auth/metadata', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (response.ok) {
          const res = await response.json();
          localStorage.setItem('login-meta-data', res);
          setSectionMember(decryptionClient(res));
        }
      }
    };
    const loginMetaData = localStorage.getItem('login-meta-data');
    const decryptLoginMetaData = !_.isEmpty(loginMetaData) ? decryptionClient(loginMetaData) : {};
    const expireCondition = !_.isEmpty(loginMetaData) ? new Date(decryptLoginMetaData?.expire) < new Date() : true;

    if (!_.isEmpty(data) && !_.isEmpty(data?.user)) {
      setSessionSevId(decryptionClient(data?.user));
      _.isEmpty(loginMetaData) || expireCondition ? checkUser() : setSectionMember(decryptLoginMetaData);
    }
  }, [data]);
  const nextSectionIndexRef = useRef(0);
  const sectionsVisibleRef = useRef([]);
  const [sectionsVisible, setSectionsVisible] = useState([]);
  const sectionRef = useRef(0);
  const _article = {
    title: articles?.metaTag?.title || WEB_TITLE,
    keywords: articles?.metaTag?.keywords || WEB_KEYWORD,
    description: articles?.metaTag?.description || WEB_DESC
  };
  const handleScroll = async () => {
    if (isShouldFetch()) {
      document.removeEventListener('scroll', handleScroll);
      await loadMoreArticles();
    }
  };
  const loadMoreArticles = async () => {
    try {
      if (nextSectionIndexRef?.current < sectionsNeedShow.length) {
        if (isShouldFetch()) {
          const currentIndex = nextSectionIndexRef.current;
          const section = sectionsNeedShow[currentIndex];

          nextSectionIndexRef.current = currentIndex + 1;
          sectionsVisibleRef.current = [...sectionsVisibleRef.current, section];
          setSectionsVisible(sectionsVisibleRef.current);

          setTimeout(() => {
            loadMoreArticles();
          }, 1000);
        } else {
          document.addEventListener('scroll', handleScroll);
        }
      }
    } catch (err) {
      console.error(`${timestamp()} ==========> INDEX ERROR : ${nextSectionIndexRef.current}`, err);
    }
  };

  /* ----------------------- DATALAYER : SCROLL_TRACKING ---------------------- */
  const handleScrollToCheckSectionOne = async () => {
    const sectionEl = document.getElementById('section-1');
    if (sectionEl) {
      sectionRef.current = 'section-1';
      document.removeEventListener('scroll', handleScrollToCheckSectionOne);
      // await sendDataLayerGlobal({
      //   key: KEY_EVENT.SECTION_SCROLL,
      //   event: KEY_EVENT.SECTION,
      //   position: '1',
      //   link: '/'
      // });
    }
  };

  const handleScrollToCheckSectionTwo = async () => {
    const sectionEl = await document.getElementById('section-2');
    if (sectionEl !== null) {
      if (sectionEl.offsetTop < document.documentElement.scrollTop) {
        sectionRef.current = 'section-2';
        document.removeEventListener('scroll', handleScrollToCheckSectionTwo);
        // await sendDataLayerGlobal({
        //   key: KEY_EVENT.SECTION_SCROLL,
        //   event: KEY_EVENT.SECTION,
        //   position: '2',
        //   link: '/'
        // });
      }
    }
  };

  const handleScrollToCheckSectionThree = async () => {
    const sectionEl = await document.getElementById('section-3');
    if (sectionEl !== null) {
      if (sectionEl.offsetTop < document.documentElement.scrollTop) {
        sectionRef.current = 'section-3';
        document.removeEventListener('scroll', handleScrollToCheckSectionThree);
        // await sendDataLayerGlobal({
        //   key: KEY_EVENT.SECTION_SCROLL,
        //   event: KEY_EVENT.SECTION,
        //   position: '3',
        //   link: '/'
        // });
      }
    }
  };

  const handleScrollToCheckSectionFour = async () => {
    const sectionEl = await document.getElementById('section-4');
    if (sectionEl !== null) {
      if (sectionEl.offsetTop < document.documentElement.scrollTop) {
        sectionRef.current = 'section-4';
        document.removeEventListener('scroll', handleScrollToCheckSectionFour);
        // await sendDataLayerGlobal({
        //   key: KEY_EVENT.SECTION_SCROLL,
        //   event: KEY_EVENT.SECTION,
        //   position: '4',
        //   link: '/'
        // });
      }
    }
  };

  const handleScrollToCheckSectionFive = async () => {
    const sectionEl = await document.getElementById('section-5');
    if (sectionEl !== null) {
      if (sectionEl.offsetTop < document.documentElement.scrollTop) {
        sectionRef.current = 'section-5';
        document.removeEventListener('scroll', handleScrollToCheckSectionFive);
        // await sendDataLayerGlobal({
        //   key: KEY_EVENT.SECTION_SCROLL,
        //   event: KEY_EVENT.SECTION,
        //   position: '5',
        //   link: '/'
        // });
      }
    }
  };

  const handleScrollToCheckSectionFooter = async () => {
    if (sectionRef.current === 'section-5' || sectionRef.current === 'section-4') {
      if (document.documentElement.scrollHeight - document.documentElement.scrollTop) {
        document.removeEventListener('scroll', handleScrollToCheckSectionFooter);
        // await sendDataLayerGlobal({
        //   key: KEY_EVENT.SECTION_SCROLL,
        //   event: KEY_EVENT.SECTION,
        //   position: 'footer',
        //   link: '/'
        // });
      }
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (!_.isEmpty(sectionMember?.user)) {
      dispatch(initialLists({ type: 'categories', data: sectionMember?.user?.favoriteCategories.filter(obj => obj.site === WEB_NAME)[0]?.categories || [] }));
      dispatch(initialLists({ type: 'tags', data: sectionMember?.user?.favoriteTags.filter(obj => obj.site === WEB_NAME)[0]?.tags || [] }));
    }
    return () => {};
  }, []);
  useEffect(() => {
    document.addEventListener('scroll', handleScrollToCheckSectionOne);
    return () => {
      document.removeEventListener('scroll', handleScrollToCheckSectionOne);
    };
  }, []);
  useEffect(() => {
    document.addEventListener('scroll', handleScrollToCheckSectionTwo);
    return () => {
      document.removeEventListener('scroll', handleScrollToCheckSectionTwo);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleScrollToCheckSectionThree);
    return () => {
      document.removeEventListener('scroll', handleScrollToCheckSectionThree);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleScrollToCheckSectionFour);
    return () => {
      document.removeEventListener('scroll', handleScrollToCheckSectionFour);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleScrollToCheckSectionFive);
    return () => {
      document.removeEventListener('scroll', handleScrollToCheckSectionFive);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleScrollToCheckSectionFooter);
    return () => {
      document.removeEventListener('scroll', handleScrollToCheckSectionFooter);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const articleDescriptionSeo = escapeChar(_article?.description) || WEB_DESC;
  return (
    <>
      {/* -------------------------------- NEXT_SEO -------------------------------- */}
      <NextSeo
        noindex={IS_NO_ROBOT_INDEX}
        nofollow={IS_NO_ROBOT_INDEX}
        title={_article.title}
        description={_article.description}
        openGraph={{
          type: 'article',
          locale: 'th_TH',
          url: WEB_URL.WEB,
          title: _article.title || WEB_TITLE,
          description: _article.description || WEB_DESC,
          images: [
            {
              url: IMAGE_DEFAULT,
              width: '1920',
              height: '1080',
              alt: WEB_NAME
            }
          ]
        }}
        additionalMetaTags={[
          {
            name: 'keywords',
            content: _article.keywords || WEB_KEYWORD
          }
        ]}
      />
      {/* --------------------------------- HEADER --------------------------------- */}
      <Head>
        <link rel='canonical' href={WEB_URL.WEB} />
        <meta property='twitter:title' content={_article?.title || WEB_TITLE} />
        <meta property='twitter:description' content={_article?.description || WEB_DESC} />
        <meta property='twitter:image' content={IMAGE_DEFAULT} />

        {/* --------------------------------- Truehit -------------------------------- */}
        <script
          type='text/javascript'
          dangerouslySetInnerHTML={{
            __html: `
                __th_page="home";
              `
          }}
        />
        {/* ---------------------------- SNIPPET : WebSite --------------------------- */}
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: `
                  {
                    "@context": "https://schema.org",
                    "@type": "WebSite",
                    "name": "${WEB_NAME}",
                    "url": "${WEB_URL.WEB}",
                    "description": "${articleDescriptionSeo}",
                    "sameAs": [
                      "${WEB_URL.FACEBOOK}",
                      "${WEB_URL.YOUTUBE}",
                      "${WEB_URL.INSTAGRAM}"
                    ],
                    "potentialAction": {
                      "@type": "SearchAction",
                      "target": "${WEB_URL.WEB}search?q={search_term_string}",
                      "query-input": "required name=search_term_string"
                    }
                  }
                }
              `
          }}
        />

        {/* ---------------------------- SPIPPET : WebSite --------------------------- */}
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: `
                  {
                    "@context": "http://schema.org",
                    "@type": "WebPage",
                    "name": "${WEB_NAME}",
                    "description": "${articleDescriptionSeo}",
                    "publisher": {
                      "@type": "ProfilePage",
                      "name": "${WEB_NAME}"
                    }
                  }
                `
          }}
        />
        {/* -------------------------- Thaileague -------------------------- */}
        {/* {SECTION_THAILEAGUE === 'true' && <script async type='text/javascript' dangerouslySetInnerHTML={{
          __html: `
                  (function(a,d,e,b,f,c,s){a[b]=a[b]||function(){a[b].q.push(arguments);};a[b].q=[];c=d.createElement(e);c.async=1;c.src="//tdn.r42tag.com/lib/"+f+".js";s=d.getElementsByTagName(e)[0];s.parentNode.insertBefore(c,s);})(window,document,"script","_st", "1347-v1");

                  _st('setPageStructure', 'partnerwebsite|khobsanam');
                  _st('addTagProperties', {'site':'partnerwebsite','category':'khobsanam'});

                  _st("loadTags");
                  `
        }} />} */}
        {/* -------------------------------- ADS : PPN - AIS ------------------------------- */}
        {/* {SECTION_THAILEAGUE === 'true' && <script async type='text/javascript' dangerouslySetInnerHTML={{
          __html: `
            window.googletag = window.googletag || {cmd: []};
            googletag.cmd.push(function() {
              googletag.defineSlot('/231599934,12270527/khobsanam/AIS/AIS', [[300, 250]], 'div-gpt-ad-1631696625382-0').addService(googletag.pubads());
              googletag.pubads().enableSingleRequest();
              googletag.pubads().collapseEmptyDivs();
              googletag.enableServices();
            });
          `
        }} />} */}
      </Head>
      {/* ------------------------ First Screen Section ----------------------- */}
      <SectionLandingPage data={dataLandingPage} />

      {covers.length > 0 && <SectionEvents data={covers} />}
      <Layout sessionSevId={sessionSevId} ads={ads}>
        {/* <DynamicAds type={KEY_ADS_TYPE.BILLBOARD} section='desktop' adsName= 'billboard' /> */}
        {/* <MediaQuery> */}
        {isExistAds(ads, KEY_ADS_POSITION.BILLBOARD) && <DynamicAds position={KEY_ADS_POSITION.BILLBOARD} data={ads} />}
        {/*  */}
        {/* ------------------------ Cover Event on top only ----------------------- */}

        {/* {SECTION_THAILEAGUE === 'true' && <SectionThaileague />} */}
        <SectionOne data={articles} ads={ads} dataSetWorldCup={dataSetWorldCup} dataTagWorldCup={dataTagWorldCup} />
        {/* ------------------------ Section Member ----------------------- */}
        {!_.isEmpty(sessionSevId) && !_.isEmpty(sectionMember) && (
          <SectionMember
            data={{
              user: dropSomeField(sectionMember, ['menu', 'tags', 'articlesTags', 'articlesCategories'])?.user,
              menu: sectionMember?.menu,
              tags: sectionMember?.tags,
              articlesTags: sectionMember?.articlesTags,
              articlesCategories: sectionMember?.articlesCategories
            }}
          />
        )}

        {sectionsVisible.includes('section-video') && <SectionVideo data={dataSectionVideo} />}
        {sectionsVisible.includes('section-two') && (
          <>
            <SectionTwo />
          </>
        )}
        {sectionsVisible.includes('section-three') && (
          <>
            {/* -------------------------------- ADS : PPN ------------------------------- */}
            {isExistAds(ads, KEY_ADS_POSITION.INARTICLE_3) && <DynamicAds position={KEY_ADS_POSITION.INARTICLE_3} data={ads} />}
            {/* --------------------------- ADS : INARTICLE - 3 -------------------------- */}
            <SectionThree />
          </>
        )}
        {sectionsVisible.includes('section-four') && (
          <>
            {/* --------------------------- ADS : INARTICLE - 4 -------------------------- */}
            {isExistAds(ads, KEY_ADS_POSITION.INARTICLE_4) && <DynamicAds position={KEY_ADS_POSITION.INARTICLE_4} data={ads} />}
            <SectionFour />
          </>
        )}
        {sectionsVisible.includes('section-five') && (
          <>
            {/* --------------------------- ADS : INARTICLE - 5 -------------------------- */}
            {isExistAds(ads, KEY_ADS_POSITION.INARTICLE_5) && <DynamicAds position={KEY_ADS_POSITION.INARTICLE_5} data={ads} />}
            <SectionFive />
            {/* --------------------------- ADS : INARTICLE - 6 -------------------------- */}
            {isExistAds(ads, KEY_ADS_POSITION.INARTICLE_6) && <DynamicAds position={KEY_ADS_POSITION.INARTICLE_6} data={ads} />}
          </>
        )}
      </Layout>
    </>
  );
};

export async function getServerSideProps(ctx) {
  const initProps = {
    statusCode: 200,
    errMessage: null,
    articles: {},
    tags: [],
    covers: [],
    dataLandingPage: [],
    dataSectionVideo: [],
    ads: [],
    dataSetWorldCup: null,
    dataTagWorldCup: [],
    session: null
  };
  const payload = {
    block1: 4,
    block2: 4,
    block3: 4,
    headerLatestNews: 5
  };
  try {
    const uri = `/api/v1.0/home/section-one${convertObjPath(payload)}`;
    const sectionData = await GET(uri);
    const coverData = await GET('/api/v1.0/imagecover');
    const landinPage = await GET('/api/landing-page');
    const sectionVideo = await GET('/api/v1.0/video/categories/video');
    const dataSet = await GET('/api/datasets');
    // World Cup

    if (!_.isEmpty(dataSet)) {
      // console.log('ddd', _.find(stat?.data?.data, { topic: 'section-world-cup' }));
      // WorldCup
      const data = _.find(dataSet, { topic: 'section-world-cup' })?.dataSet;
      if (!_.isEmpty(data)) {
        initProps.dataSetWorldCup = data;
        const tagName = convertDatasets(data, 'tag-name', true);
        const resWC = await GET(`/api/search/tag${convertObjPath({ name: tagName })}`);
        if (!_.isEmpty(resWC)) {
          initProps.dataTagWorldCup = resWC;
        }
      }
    }
    // const dataSetsAll = await GET('/api/datasets');
    // If don't have data return [];
    // If have data return [{ id: 1 }]
    // If can't call api return { data: null, msg: null }

    // if (!_.isEmpty(dataSetsAll?.data)) {
    //   const dataSets = dataSetsAll.data; // default datasets
    //   console.log('dataSets', dataSets);
    //   initProps.videos = _.find(dataSets, { topic: 'iframe' }) || [];
    // }

    if (!_.isEmpty(sectionData)) {
      initProps.articles = sectionData;
      initProps.ads = sectionData?.ads;
      initProps.tags = sectionData.tags || [];
    }
    if (!_.isEmpty(coverData)) initProps.covers = coverData;
    if (!_.isEmpty(landinPage)) initProps.dataLandingPage = landinPage;
    if (!_.isEmpty(sectionVideo)) initProps.dataSectionVideo = sectionVideo;
  } catch (err) {
    console.error(`${timestamp()} ==========> PAGE_INDEX ERROR : `, err.message);
    initProps.errMessage = err.message;
    initProps.statusCode = err?.response?.status || 500;
  }

  return {
    props: initProps
  };
}

export default PageIndex;
